<template>
  <div
    v-if="noWrap && show"
    :class="['zoverlay', formattedVariant, { 'zoverlay-fixed': fixed, 'zoverlay-opaque': opaque }]"
    :style="{ zIndex: zIndex }"
    @click="emit('click', $event)"
  >
    <div class="zoverlay-bg" />
    <div :class="['zoverlay-content', { 'zoverlay-content-center': !noCenter }]">
      <slot name="overlay">
        <ZSpinner />
        <div
          v-if="message"
          class="mt-3"
        >
          {{ message }}
        </div>
      </slot>
    </div>
  </div>

  <div
    v-else
    class="zoverlay-wrapper"
  >
    <!-- wrapped content -->
    <slot />

    <!-- overlay content -->
    <div
      v-if="show"
      :class="['zoverlay', formattedVariant, { 'zoverlay-fixed': fixed, 'zoverlay-opaque': opaque }]"
      :style="{ zIndex: zIndex }"
    >
      <div class="zoverlay-bg" />
      <div :class="['zoverlay-content', { 'zoverlay-content-center': !noCenter }]">
        <slot name="overlay">
          <ZSpinner />
          <div
            v-if="message"
            class="mt-3"
          >
            {{ message }}
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OverlayVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  show: boolean
  noWrap?: boolean
  fixed?: boolean
  noCenter?: boolean
  zIndex?: string
  variant?: OverlayVariant
  opaque?: boolean
  message?: string
}>(), {
  noWrap: false,
  fixed: false,
  noCenter: false,
  zIndex: '10',
  variant: 'light',
  opaque: false,
  message: '',
})

const emit = defineEmits<{
  click: [event: Event]
}>()

const formattedVariant = computed(() => `zoverlay-${props.variant}`)
</script>
